<template>
  <BModal
    :modal-name="modalName"
    :width="modalWidth"
    :height="modalHeight"
  >
    <BHeightAdjuster>
      <BModalHeader
        :title="$t('leadView.title')"
        @modal-close="$modal.hide(modalName)"
      />
      <BModalBody>
        <div class="message mb-600">
          {{ $t('leadView.modalMessage') }}
        </div>
        <VWait for="getLeadViewActionWait">
          <BTabs
            position="left"
            :label-items="tabLabelItems"
          >
            <template #form>
              <BLayout column>
                <BListItem>
                  <template #header>
                    <div>{{ $t('leadView.name' ) }}</div>
                  </template>
                  <BInput
                    v-model="leadViewName"
                    v-model:valid="valid"
                    autofocus
                    :rules="[requiredRule]"
                  />
                </BListItem>
                <BListItem class="mt-200">
                  <template #header>
                    <div>{{ $t('leadView.description' ) }}</div>
                  </template>
                  <BTextarea
                    v-model="leadViewDescription"
                    resize="none"
                    :autosize="{ minRows: 4, maxRows: 10 }"
                  />
                </BListItem>
              </BLayout>
            </template>
            <template #sort>
              <div v-if="isSorterdColumnsChanged">
                <BLayout>
                  <div class="before-change mb-600">
                    <span>{{ $t('general.beforeChange') }}</span>
                  </div>
                  <div class="after-change mb-600">
                    <span>{{ $t('general.afterChange') }}</span>
                  </div>
                </BLayout>
                <BLayout>
                  <BSortText
                    class="sort-box"
                    :order-by="leadView.sortOrder"
                    :item-text="getColumnName(leadView.sortKey)"
                    is-active
                    is-display-only
                  />
                  <BSortText
                    class="sort-box"
                    :order-by="leadListSortOrder"
                    :item-text="getColumnName(leadListSortKey)"
                    is-active
                    is-display-only
                  />
                </BLayout>
              </div>
              <BEmptyBox
                v-else
                class="pt-500 pb-500"
                display-only
              >
                <span>{{ $t('general.noChange') }}</span>
              </BEmptyBox>
            </template>
            <template #filter>
              <div v-if="isFilterParamsChanged">
                <BLayout>
                  <div class="before-change mb-600">
                    <span>{{ $t('general.beforeChange') }}</span>
                  </div>
                  <div class="after-change mb-600">
                    <span>{{ $t('general.afterChange') }}</span>
                  </div>
                </BLayout>
                <div
                  v-if="filteredSystemItemComponentNames.length > 0"
                  class="sub-title"
                >
                  {{ $t('filterSelect.systemInfo.title') }}
                </div>
                <BLayout
                  v-for="([ key, value ]) in filteredSystemItemComponentNames"
                  :key="key"
                >
                  <Component
                    :is="key"
                    v-if="isOldExistFilterParam(value)"
                    :title="$t(`filterSelect.systemInfo.item.${value}`)"
                    class="filter-box"
                    :lead-list-filter-params="filterParams"
                    is-display-only
                    :key-name="value"
                  />
                  <BEmptyBox
                    v-else-if="isNewExistFilterParam(value)"
                    class="filter-b-empty-box"
                    display-only
                  />
                  <Component
                    :is="key"
                    v-if="isNewExistFilterParam(value)"
                    :title="$t(`filterSelect.systemInfo.item.${value}`)"
                    class="filter-box"
                    :lead-list-filter-params="leadListFilterParams"
                    is-display-only
                    :key-name="value"
                  />
                  <BEmptyBox
                    v-else-if="isOldExistFilterParam(value)"
                    class="filter-b-empty-box"
                    display-only
                  />
                </BLayout>
                <div
                  v-if="filteredCompanyItemComponentNames.length > 0"
                  class="sub-title"
                >
                  {{ $t('filterSelect.companyInfo.title') }}
                </div>
                <BLayout
                  v-for="([ key, value ]) in filteredCompanyItemComponentNames"
                  :key="key"
                >
                  <Component
                    :is="key"
                    v-if="isOldExistFilterParam(value)"
                    class="filter-box"
                    :title="$t(`filterSelect.companyInfo.item.${value}`)"
                    :lead-list-filter-params="filterParams"
                    is-display-only
                    :key-name="value"
                  />
                  <BEmptyBox
                    v-else-if="isNewExistFilterParam(value)"
                    class="filter-b-empty-box"
                    display-only
                  />
                  <Component
                    :is="key"
                    v-if="isNewExistFilterParam(value)"
                    class="filter-box"
                    :title="$t(`filterSelect.companyInfo.item.${value}`)"
                    :lead-list-filter-params="leadListFilterParams"
                    is-display-only
                    :key-name="value"
                  />
                  <BEmptyBox
                    v-else-if="isOldExistFilterParam(value)"
                    class="filter-b-empty-box"
                    display-only
                  />
                </BLayout>
                <div
                  v-if="filteredTargetItemComponentNames.length > 0"
                  class="sub-title"
                >
                  {{ $t('filterSelect.callTargetInfo.title') }}
                </div>
                <BLayout
                  v-for="([ key, value ]) in filteredTargetItemComponentNames"
                  :key="key"
                >
                  <Component
                    :is="key"
                    v-if="isOldExistFilterParam(value)"
                    class="filter-box"
                    :title="$t(`filterSelect.callTargetInfo.item.${value}`)"
                    :lead-list-filter-params="filterParams"
                    is-display-only
                    :key-name="value"
                  />
                  <BEmptyBox
                    v-else-if="isNewExistFilterParam(value)"
                    class="filter-b-empty-box"
                    display-only
                  />
                  <Component
                    :is="key"
                    v-if="isNewExistFilterParam(value)"
                    class="filter-box"
                    :title="$t(`filterSelect.callTargetInfo.item.${value}`)"
                    :lead-list-filter-params="leadListFilterParams"
                    is-display-only
                    :key-name="value"
                  />
                  <BEmptyBox
                    v-else-if="isOldExistFilterParam(value)"
                    class="filter-b-empty-box"
                    display-only
                  />
                </BLayout>
                <div
                  v-if="filteredHistoryItemComponentNames.length > 0 || filteredBulkMailItemComponentNames.length > 0"
                  class="sub-title"
                >
                  {{ $t('filterSelect.historyInfo.title') }}
                </div>
                <BLayout
                  v-for="([ key, value ]) in filteredHistoryItemComponentNames"
                  :key="key"
                >
                  <Component
                    :is="key"
                    v-if="isOldExistFilterParam(value)"
                    class="filter-box"
                    :lead-list-filter-params="filterParams"
                    is-display-only
                    :title="$t(`filterSelect.historyInfo.item.${value}`)"
                    :key-name="value"
                  />
                  <BEmptyBox
                    v-else-if="isNewExistFilterParam(value)"
                    class="filter-b-empty-box"
                    display-only
                  />
                  <Component
                    :is="key"
                    v-if="isNewExistFilterParam(value)"
                    class="filter-box"
                    :lead-list-filter-params="leadListFilterParams"
                    is-display-only
                    :title="$t(`filterSelect.historyInfo.item.${value}`)"
                    :key-name="value"
                  />
                  <BEmptyBox
                    v-else-if="isOldExistFilterParam(value)"
                    class="filter-b-empty-box"
                    display-only
                  />
                </BLayout>
                <!-- NOTE: ヒストリー情報として表示 -->
                <BLayout
                  v-for="([ key, value ]) in filteredBulkMailItemComponentNames"
                  :key="key"
                >
                  <Component
                    :is="key"
                    v-if="isOldExistFilterParam(value)"
                    class="filter-box"
                    :lead-list-filter-params="filterParams"
                    is-display-only
                    :key-name="value"
                  />
                  <BEmptyBox
                    v-else-if="isNewExistFilterParam(value)"
                    class="filter-b-empty-box"
                    display-only
                  />
                  <Component
                    :is="key"
                    v-if="isNewExistFilterParam(value)"
                    class="filter-box"
                    :lead-list-filter-params="leadListFilterParams"
                    is-display-only
                    :key-name="value"
                  />
                  <BEmptyBox
                    v-else-if="isOldExistFilterParam(value)"
                    class="filter-b-empty-box"
                    display-only
                  />
                </BLayout>
                <div
                  v-if="filteredLeadAdditionalItemComponentNames.length > 0"
                  class="sub-title"
                >
                  {{ $t('filterSelect.leadAdditionalInfo.title') }}
                </div>
                <BLayout
                  v-for="([ key, value ]) in filteredLeadAdditionalItemComponentNames"
                  :key="key"
                >
                  <Component
                    :is="key"
                    v-if="isOldExistFilterParam(value)"
                    :title="$t(`filterSelect.leadAdditionalInfo.item.${value}`)"
                    class="filter-box"
                    :lead-list-filter-params="filterParams"
                    is-display-only
                    :key-name="value"
                  />
                  <BEmptyBox
                    v-else-if="isNewExistFilterParam(value)"
                    class="filter-b-empty-box"
                    display-only
                  />
                  <Component
                    :is="key"
                    v-if="isNewExistFilterParam(value)"
                    :title="$t(`filterSelect.leadAdditionalInfo.item.${value}`)"
                    class="filter-box"
                    :lead-list-filter-params="leadListFilterParams"
                    is-display-only
                    :key-name="value"
                  />
                  <BEmptyBox
                    v-else-if="isOldExistFilterParam(value)"
                    class="filter-b-empty-box"
                    display-only
                  />
                </BLayout>
                <div
                  v-if="filteredCustomFieldItemsForPanel.length > 0"
                  class="sub-title"
                >
                  {{ $t('filterSelect.customField.title') }}
                </div>
                <BLayout
                  v-for="({ fieldKey, before, after }) in filteredCustomFieldItemsForPanel"
                  :key="fieldKey"
                >
                  <CustomFieldItem
                    :info="before.info"
                    :custom-field-item="before.item"
                  />
                  <CustomFieldItem
                    :info="after.info"
                    :custom-field-item="after.item"
                  />
                </BLayout>
                <div
                  v-if="filteredNextActionItemComponentNames.length > 0"
                  class="sub-title"
                >
                  {{ $t('filterSelect.nextActions.title') }}
                </div>
                <BLayout
                  v-for="([ key, value ]) in filteredNextActionItemComponentNames"
                  :key="key"
                >
                  <Component
                    :is="key"
                    v-if="isOldExistFilterParam(value)"
                    class="filter-box"
                    :lead-list-filter-params="filterParams"
                    :key-name="value"
                    is-display-only
                    :title="$t(`filterSelect.nextActions.item.${value}`)"
                    :true-text="getNextActionsCompletedPresentTrueText(value)"
                    :false-text="getNextActionsCompletedPresentFalseText(value)"
                  />
                  <BEmptyBox
                    v-else-if="isNewExistFilterParam(value)"
                    class="filter-b-empty-box"
                    display-only
                  />
                  <Component
                    :is="key"
                    v-if="isNewExistFilterParam(value)"
                    class="filter-box"
                    :lead-list-filter-params="leadListFilterParams"
                    :key-name="value"
                    is-display-only
                    :title="$t(`filterSelect.nextActions.item.${value}`)"
                    :true-text="getNextActionsCompletedPresentTrueText(value)"
                    :false-text="getNextActionsCompletedPresentFalseText(value)"
                  />
                  <BEmptyBox
                    v-else-if="isOldExistFilterParam(value)"
                    class="filter-b-empty-box"
                    display-only
                  />
                </BLayout>
                <div
                  v-if="filteredDuplicateItemComponentNames.length > 0"
                  class="sub-title"
                >
                  {{ $t('filterSelect.duplicateInfo.title') }}
                </div>
                <BLayout
                  v-for="([ key, value ]) in filteredDuplicateItemComponentNames"
                  :key="key"
                >
                  <Component
                    :is="key"
                    v-if="isOldExistFilterParam(value)"
                    class="filter-box"
                    :lead-list-filter-params="filterParams"
                    :key-name="value"
                    is-display-only
                    :title="$t(`filterSelect.duplicateInfo.item.${value}`)"
                  />
                  <BEmptyBox
                    v-else-if="isNewExistFilterParam(value)"
                    class="filter-b-empty-box"
                    display-only
                  />
                  <Component
                    :is="key"
                    v-if="isNewExistFilterParam(value)"
                    class="filter-box"
                    :lead-list-filter-params="leadListFilterParams"
                    :key-name="value"
                    is-display-only
                    :title="$t(`filterSelect.duplicateInfo.item.${value}`)"
                  />
                  <BEmptyBox
                    v-else-if="isOldExistFilterParam(value)"
                    class="filter-b-empty-box"
                    display-only
                  />
                </BLayout>
              </div>
              <BEmptyBox
                v-else
                class="pt-500 pb-500"
                display-only
              >
                <span>{{ $t('general.noChange') }}</span>
              </BEmptyBox>
            </template>
            <template #displyColumn>
              <BLayout v-if="isDisplayColumnsChanged">
                <div class="before-change-filter-panel mb-600">
                  <div class="before-change mb-600">
                    <span>{{ $t('general.beforeChange') }}</span>
                  </div>
                  <div
                    v-for="column in displayColumns"
                    :key="column"
                    class="column-box px-200 mb-100"
                    :class="{'is-deleted': isNewExistLeadListColumn(column)}"
                  >
                    <span>{{ getColumnName(column) }}</span>
                  </div>
                </div>
                <div class="after-change-filter-panel mb-600">
                  <div class="after-change mb-600">
                    <span>{{ $t('general.afterChange') }}</span>
                  </div>
                  <div
                    v-for="column in leadListSelectedColumns"
                    :key="column"
                    class="column-box px-200 mb-100"
                    :class="{'is-added': isOldExistLeadListColumn(column)}"
                  >
                    <span>{{ getColumnName(column) }}</span>
                  </div>
                </div>
              </BLayout>
              <BEmptyBox
                v-else
                class="pt-500 pb-500"
                display-only
              >
                <span>{{ $t('general.noChange') }}</span>
              </BEmptyBox>
            </template>
          </BTabs>
        </VWait>
      </BModalBody>
      <BModalFooter>
        <BLayout
          class="mt-300 mx-400"
          justify-center
          align-center
        >
          <BBtn
            class="mr-600"
            text
            :disabled="$wait.is('updateLeadViewWait')"
            @click="$modal.hide(modalName)"
          >
            {{ $t('general.cancel.text') }}
          </BBtn>
          <BBtn
            type="primary"
            :loading="$wait.is('updateLeadViewWait')"
            @click="handleUpdateLeadView"
          >
            {{ $t('general.update') }}
          </BBtn>
        </BLayout>
      </BModalFooter>
    </BHeightAdjuster>
  </BModal>
</template>

<script lang="ts">
import camelcaseKeys from 'camelcase-keys'
import { defineComponent } from 'vue'
import { mapWaitingActions } from 'vue-wait'
import { mapGetters } from 'vuex'
import AssignedOperator from '@/components/organisms/user/leadList/filter/detail/AssignedOperator.vue'
import BulkMailEvents from '@/components/organisms/user/leadList/filter/detail/BulkMailEvents.vue'
import BulkMails from '@/components/organisms/user/leadList/filter/detail/BulkMails.vue'
import CallResults from '@/components/organisms/user/leadList/filter/detail/CallResults.vue'
import CompanyItemsBusinessCategory from '@/components/organisms/user/leadList/filter/detail/CompanyItemsBusinessCategory.vue'
import CompanyItemsNumberOfEmployees from '@/components/organisms/user/leadList/filter/detail/CompanyItemsNumberOfEmployees.vue'
import CallTargetsCreatedAt from '@/components/organisms/user/leadList/filter/detail/DateTimeFilter.vue'
import NextActionsDoneAt from '@/components/organisms/user/leadList/filter/detail/DateTimeFilter.vue'
import NextActionsReservedAt from '@/components/organisms/user/leadList/filter/detail/DateTimeFilter.vue'
import OpportunitiesCreatedAt from '@/components/organisms/user/leadList/filter/detail/DateTimeFilter.vue'
import OpportunitiesStartedAt from '@/components/organisms/user/leadList/filter/detail/DateTimeFilter.vue'
import LastCallCalledAt from '@/components/organisms/user/leadList/filter/detail/DateTimeFilter.vue'
import LeadSourcesRegisteredAt from '@/components/organisms/user/leadList/filter/detail/DateTimeFilter.vue'
import DuplicateEmail from '@/components/organisms/user/leadList/filter/detail/DuplicateEmail.vue'
import FreeTags from '@/components/organisms/user/leadList/filter/detail/FreeTags.vue'
import LastLeadStage from '@/components/organisms/user/leadList/filter/detail/LastLeadStage.vue'
import LastLeadStageStayDays from '@/components/organisms/user/leadList/filter/detail/LastLeadStageStayDays.vue'
import LeadSources from '@/components/organisms/user/leadList/filter/detail/LeadSources.vue'
import NextActionsActionType from '@/components/organisms/user/leadList/filter/detail/NextActionsActionType.vue'
import NextActionsPriority from '@/components/organisms/user/leadList/filter/detail/NextActionsPriority.vue'
import NumberOfCall from '@/components/organisms/user/leadList/filter/detail/NumberFilter.vue'
import OpportunitiesPresent from '@/components/organisms/user/leadList/filter/detail/PresentFilter.vue'
import UnsubscriptionPresent from '@/components/organisms/user/leadList/filter/detail/PresentFilter.vue'
import NextActionsPresent from '@/components/organisms/user/leadList/filter/detail/PresentFilter.vue'
import NextActionsCompletedPresent from '@/components/organisms/user/leadList/filter/detail/PresentFilter.vue'
import NextActionsOwnerPresent from '@/components/organisms/user/leadList/filter/detail/PresentFilter.vue'
import SfRelatedLeadStatus from '@/components/organisms/user/leadList/filter/detail/SfRelatedLeadStatus.vue'
import TargetItemsDepartment from '@/components/organisms/user/leadList/filter/detail/TargetItemsDepartment.vue'
import TargetItemsEmail from '@/components/organisms/user/leadList/filter/detail/TargetItemsEmail.vue'
import TargetItemsPosition from '@/components/organisms/user/leadList/filter/detail/TargetItemsPosition.vue'
import CompanyItemsCompanyName from '@/components/organisms/user/leadList/filter/detail/TextFilter.vue'
import CompanyItemsCompanyAddress from '@/components/organisms/user/leadList/filter/detail/TextFilter.vue'
import CompanyItemsCompanyTel from '@/components/organisms/user/leadList/filter/detail/TextFilter.vue'
import TargetItemsTel from '@/components/organisms/user/leadList/filter/detail/TextFilter.vue'
import TargetItemsName from '@/components/organisms/user/leadList/filter/detail/TextFilter.vue'
import TargetItemsEmailText from '@/components/organisms/user/leadList/filter/detail/TextFilter.vue'
import NextActionsOwner from '@/components/organisms/user/leadList/filter/detail/UserSelectFilter.vue'
import NextActionsDoneBy from '@/components/organisms/user/leadList/filter/detail/UserSelectFilter.vue'
import inputValidation from '@/mixins/input_validation'
import queryParameter from '@/mixins/query_parameter'
import { formatShorterDateTime, isDateValid } from '@/utils/date-time'
import { getIncExc } from '@/utils/object'
import CustomFieldItem from './leadViewUpdateModal/CustomFieldItem.vue';

export default defineComponent({
  components: {
    OpportunitiesPresent,
    UnsubscriptionPresent,
    TargetItemsEmail,
    TargetItemsEmailText,
    CustomFieldItem,
    OpportunitiesStartedAt,
    OpportunitiesCreatedAt,
    BulkMails,
    BulkMailEvents,
    CompanyItemsCompanyName,
    CompanyItemsCompanyTel,
    CompanyItemsCompanyAddress,
    CompanyItemsBusinessCategory,
    CompanyItemsNumberOfEmployees,
    TargetItemsDepartment,
    TargetItemsPosition,
    TargetItemsName,
    TargetItemsTel,
    CallResults,
    LastCallCalledAt,
    NumberOfCall,
    LastLeadStage,
    LastLeadStageStayDays,
    AssignedOperator,
    FreeTags,
    LeadSources,
    LeadSourcesRegisteredAt,
    NextActionsActionType,
    NextActionsPriority,
    NextActionsReservedAt,
    NextActionsDoneAt,
    NextActionsPresent,
    NextActionsCompletedPresent,
    CallTargetsCreatedAt,
    SfRelatedLeadStatus,
    DuplicateEmail,
    NextActionsOwner,
    NextActionsOwnerPresent,
    NextActionsDoneBy,
  },
  mixins: [
    queryParameter,
    inputValidation,
  ],
  data () {
    return {
      valid: false,
      modalWidth: '660px',
      modalHeight: '660px',
      tabLabelItems: [
        { label: '概要', slotName: 'form' },
        { label: 'フィルタ条件', slotName: 'filter' },
        { label: 'ソート条件', slotName: 'sort' },
        { label: '表示項目', slotName: 'displyColumn' },
      ],
      leadViewName: '',
      leadViewDescription: '',
    }
  },
  computed: {
    ...mapGetters('user', [
      'leadView',
      'customFields',
    ]),
    ...mapGetters('userUi', [
      'leadListSortKey',
      'leadListSortOrder',
      'leadListSelectedColumns',
      'leadListFilterParams',
      'leadListPageParams',
    ]),
    modalName () {
      return 'LeadViewUpdateModal'
    },
    systemItemComponentNames() {
      return {
        'CallTargetsCreatedAt': 'callTargetsCreatedAt',
        'SfRelatedLeadStatus': 'sfRelatedLeadStatus',
      }
    },
    companyItemComponentNames () {
      return {
        'CompanyItemsCompanyName': 'companyItemsCompanyName',
        'CompanyItemsCompanyTel': 'companyItemsCompanyTel',
        'CompanyItemsCompanyAddress': 'companyItemsCompanyAddress',
        'CompanyItemsBusinessCategory': 'companyItemsBusinessCategory',
        'CompanyItemsNumberOfEmployees': 'companyItemsNumberOfEmployees',
      }
    },
    targetItemComponentNames () {
      return {
        'TargetItemsDepartment': 'targetItemsDepartment',
        'TargetItemsPosition': 'targetItemsPosition',
        'TargetItemsEmail': 'targetItemsEmail',
        'TargetItemsName': 'targetItemsName',
        'TargetItemsEmailText': 'targetItemsEmailText',
        'TargetItemsTel': 'targetItemsTel',
      }
    },
    historyItemComponentNames () {
      return {
        'CallResults': 'callResults',
        'LastCallCalledAt': 'lastCallCalledAt',
        'NumberOfCall': 'numberOfCall',
        'OpportunitiesPresent': 'opportunitiesPresent',
        'OpportunitiesCreatedAt': 'opportunitiesCreatedAt',
        'OpportunitiesStartedAt': 'opportunitiesStartedAt',
        'UnsubscriptionPresent': 'unsubscriptionPresent',
        'LastLeadStageStayDays': 'lastLeadStageStayDays',
      }
    },
    bulkMailItemComponentNames() {
      return {
        'BulkMails': 'bulkMails',
        'BulkMailEvents': 'bulkMailEvents',
      }
    },
    leadAdditionalItemComponentNames () {
      return {
        'LastLeadStage': 'lastLeadStage',
        'AssignedOperator': 'assignedOperator',
        'LeadSources': 'leadSources',
        'LeadSourcesRegisteredAt': 'leadSourcesRegisteredAt',
        'FreeTags': 'freeTags',
      }
    },
    nextActionItemComponentNames () {
      return {
        'NextActionsActionType': 'nextActionsActionType',
        'NextActionsPriority': 'nextActionsPriority',
        'NextActionsReservedAt': 'nextActionsReservedAt',
        'NextActionsDoneAt': 'nextActionsDoneAt',
        'NextActionsPresent': 'nextActionsPresent',
        'NextActionsCompletedPresent': 'nextActionsCompletedPresent',
        'NextActionsOwner': 'nextActionsOwner',
        'NextActionsOwnerPresent': 'nextActionsOwnerPresent',
        'NextActionsDoneBy': 'nextActionsDoneBy',
      }
    },
    duplicateItemComponentNames () {
      return {
        'DuplicateEmail': 'duplicateEmail',
      }
    },
    filterParams () {
      if (this.leadView.filterParams) {
        return camelcaseKeys(this.$JSON.parse(this.leadView.filterParams), { deep: true, exclude: [/^custom_field_.*[^items]/g] })
      } else {
        return {}
      }
    },
    displayColumns () {
      if (this.leadView.displayColumns) {
        return this.$JSON.parse(this.leadView.displayColumns)
      } else {
        return []
      }
    },
    columnNames () {
      // { key, name } にまとめる
      const columns = {}
      const categories = ['companyInfo', 'callTargetInfo', 'historyInfo', 'leadAdditionalInfo', 'lastActivityInfo', 'systemAdditionalInfo', 'duplicateInfo']
      categories.forEach(category => {
        Object.keys(this.$tm(`filterSelect.${category}.column`)).forEach(key => {
          columns[key] = this.$t(`filterSelect.${category}.column.${key}`)
        })
      })
      this.customFields.forEach((customField) => {
        columns[customField.fieldKey] = customField.name
      })
      return columns
    },
    isFilterParamsChanged () {
      return this.$JSON.stringify(this.filterParams) !== this.$JSON.stringify(this.leadListFilterParams)
    },
    isSorterdColumnsChanged () {
      return this.leadView.sortOrder !== this.leadListSortOrder || this.leadView.sortKey !== this.leadListSortKey
    },
    isDisplayColumnsChanged () {
      return this.displayColumns.toString() !== this.leadListSelectedColumns.toString()
    },
    filteredSystemItemComponentNames() {
      return this.filterComponentNames(this.systemItemComponentNames);
    },
    filteredCompanyItemComponentNames() {
      return this.filterComponentNames(this.companyItemComponentNames);
    },
    filteredTargetItemComponentNames() {
      return this.filterComponentNames(this.targetItemComponentNames);
    },
    filteredHistoryItemComponentNames() {
      return this.filterComponentNames(this.historyItemComponentNames);
    },
    filteredBulkMailItemComponentNames() {
      return this.filterComponentNames(this.bulkMailItemComponentNames);
    },
    filteredLeadAdditionalItemComponentNames() {
      return this.filterComponentNames(this.leadAdditionalItemComponentNames);
    },
    filteredNextActionItemComponentNames() {
      return this.filterComponentNames(this.nextActionItemComponentNames);
    },
    filteredDuplicateItemComponentNames() {
      return this.filterComponentNames(this.duplicateItemComponentNames);
    },
    filteredCustomFieldItemsForPanel() {
      const beforeCustomFieldItems = this.filterParams?.customFieldItems || {};
      const afterCustomFieldItems = this.leadListFilterParams?.customFieldItems || {};
      const customFieldKeys = this.customFields.map(({ fieldKey }) => fieldKey)
        .concat(Object.keys(beforeCustomFieldItems), Object.keys(afterCustomFieldItems));

      // NOTE: 一度Setに変換することでuniqueにしている
      return Array.from(new Set(customFieldKeys))
        .map((fieldKey) => ({
          fieldKey,
          beforeItem: beforeCustomFieldItems[fieldKey],
          afterItem: afterCustomFieldItems[fieldKey],
        }))
        .filter(({ beforeItem, afterItem }) => this.isFilterItemChanged(beforeItem, afterItem))
        .map(({ fieldKey, beforeItem, afterItem }) => {
          const customField = this.customFields.find((c) => c.fieldKey === fieldKey);
          const makeItems = (item) => ({
            item,
            info: item ? this.buildCustomFieldItem(item, customField, fieldKey) : null,
          });

          return {
            fieldKey, 
            before: makeItems(beforeItem),
            after: makeItems(afterItem),
          };
        });
    },
  },
  watch: {
    leadView(value) {
      if (!value) return
      this.leadViewName = value.name
      this.leadViewDescription = value.description
    },
  },
  methods: {
    ...mapWaitingActions('user', {
      getLeadViewAction: { action: 'getLeadViewAction', loader: 'getLeadViewActionWait' },
      updateLeadViewAction: 'updateLeadViewWait',
    }),
    filterComponentNames(componentNames) {
      return Object.entries(componentNames)
        .filter(([, value]) => {
          return this.isFilterItemChanged(this.filterParams[value], this.leadListFilterParams[value]);
        });
    },
    buildCustomFieldItem(customFieldParams, customField, fieldKey) {
      const incExc = getIncExc(customFieldParams)
      const params = customFieldParams[incExc].or

      let dataType = null
      if (customField == null) {
        if (Array.isArray(params)) {
          if (isDateValid(params[0])) {
            dataType = 'datetime'
          } else if (params.length === 3 && typeof params[2] === 'boolean') {
            dataType = 'number'
          } else {
            dataType = 'option'
          }
        } else if (typeof params === 'string') {
          dataType = 'text'
        }
      } else {
        dataType = customField.dataType
      }

      const customFieldItem = {
        fieldKey,
        dataType,
        name: customField == null ? this.$t('customField.alreadyDeleted') : customField.name,
        value: '-',
      }

      if (customField && customField.dataType === 'text' && params && params.length > 0) {
        customFieldItem.value = params
      } else if (customField && customField.dataType === 'datetime' && params && params.length >= 2) {
        const start = formatShorterDateTime(params[0])
        const end = formatShorterDateTime(params[1])
        customFieldItem.value = `${start} 〜 ${end}`
      } else if (customField && ['option', 'multi_option'].includes(customField.dataType) && params.length > 0) {
        const customFieldOptionValues = customFieldItem.value = params.map((v) => {
          if (parseInt(v) === -1) {
            return this.$t('general.blankInput')
          } else {
            const option = customField.options.find(o => o.id === parseInt(v))
            return option ? option.label : this.$t('general.delete.done')
          }
        })
        customFieldItem.value = customFieldOptionValues.join(' / ')
      } else if (customField && customField.dataType === 'number' && params && params.length === 3) {
        customFieldItem.value = params
      }
      // TODO: number型も↑の分岐になにか追加する必要あるかも
      return customFieldItem
    },
    getColumnName (key) {
      if (key === undefined) return
      const isCustomField = key.indexOf('custom_field_') !== -1
      if (isCustomField) {
        const customField = this.getCustomFieldByFieldKey(key)
        return customField == null ? '-' : customField.name
      } else {
        return this.columnNames[key]
      }
    },
    isOldExistLeadListColumn (columnName) {
      return this.displayColumns.indexOf(columnName) === -1
    },
    isNewExistLeadListColumn (columnName) {
      return this.leadListSelectedColumns.indexOf(columnName) === -1
    },
    isFilterItemChanged(beforeItem, afterItem) {
      const isBeforeExist = beforeItem != null;
      const isAfterExist = afterItem != null;
      if (!isBeforeExist && !isAfterExist) return false
      if (!isBeforeExist || !isAfterExist) return true

      return this.$JSON.stringify(beforeItem) !== this.$JSON.stringify(afterItem)
    },
    isOldExistFilterParam (paramName) {
      return this.filterParams[paramName] != null
    },
    isNewExistFilterParam (paramName) {
      return this.leadListFilterParams[paramName] != null
    },
    getCustomFieldByFieldKey (fieldKey) {
      if (this.customFields == null) return null
      const filteredCustomField = this.customFields.filter(item => item.fieldKey === fieldKey)
      return filteredCustomField.length === 0 ? null : filteredCustomField[0]
    },
    async handleUpdateLeadView () {
      const displayColumns = this.$JSON.stringify(this.leadListSelectedColumns)
      await this.updateLeadViewAction({
        leadViewId: this.leadView.id,
        body: {
          name: this.leadViewName,
          description: this.leadViewDescription,
          filterParams: { lf: this.leadListFilterParams },
          displayColumns,
          sortKey: this.leadListSortKey,
          sortOrder: this.leadListSortOrder,
        },
      })
      this.$modal.hide(this.modalName)
      this.leadViewName = ''
      this.leadViewDescription = ''
      this.$toast.show({ message: this.$t('general.done.of', { action: this.$t('leadView.saveMessage') }) })
      await this.getLeadViewAction({ leadViewId: this.leadView.id })
    },
    getNextActionsCompletedPresentTrueText(value) {
      return this.isNextActionsCompletedPresent(value) ? this.$t('nextAction.status.enum.done') : undefined
    },
    getNextActionsCompletedPresentFalseText(value) {
      return this.isNextActionsCompletedPresent(value) ? this.$t('nextAction.status.enum.unfinished') : undefined
    },
    isNextActionsCompletedPresent(value) {
      return value === 'nextActionsCompletedPresent'
    },
  },
});
</script>

<style lang="scss" scoped>
  .message {
    color: $textcolor-light;
  }

  .after-change, .before-change {
    width: 50%;
    border-radius: 2px;
    text-align: center;
    padding: 5px 0;
    margin-left: auto;
    margin-right: auto;
  }
  .after-change {
    color: $textcolor-white;
    background-color: $basecolor-success;
  }
  .before-change {
    background-color: $concrete-light;
  }
  
  :deep(.el-row) {
    .filter-b-empty-box {
      width: calc(50% - 4px);
      margin: 16px 2px 12px 2px;
      padding: 32px 0 24px 0;
    }

    .filter-card {
      margin-left: 0;
      width: 100%;
    }

    // FIXME: Vue migrate buildのためFilter componentにfilter-boxクラス指定が渡っていない
    // 暫定的にfilter-boxの直下の要素を指定しておく
    // .filter-box, .sort-box {
    & > .el-tooltip__trigger {
      // NOTE: inline要素でサイズ指定ができないため
      display: inline-block;
    }
    & > .el-tooltip__trigger, .filter-box, .sort-box {
      padding: 0 2px;
      width: 50%;
    }
  }

  .before-change-filter-panel, .after-change-filter-panel {
    width: 100%;
  }

  .column-box {
    text-align: center;

    &.is-added {
      color: $basecolor-primary;
    }

    &.is-deleted {
      color: $basecolor-secondary;
    }
  }
  .b-modal-footer {
    background-color: $bgcolor-base;
    padding: $basespace-200 $basespace-400;
    border-top: 1px solid $bdcolor-light;
  }

  .sub-title {
    font-size: 12px;
    color: $textcolor-light;
    padding-bottom: $basespace-50;
    border-bottom: 1px solid $bdcolor-base;
    margin-bottom: $basespace-100;
  }

  :deep(.el-tabs) {
    .el-tabs__active-bar {
      // NOTE: 既存レイアウトを踏襲するため、active時の下線の色を消す
      background-color: var(--el-border-color-light);
    }
  }
</style>
