import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "item-title" }
const _hoisted_2 = { class: "number-input-container" }
const _hoisted_3 = { class: "number-input-assist-text" }
const _hoisted_4 = { class: "number-input-container mt-300" }
const _hoisted_5 = { class: "number-input-assist-text" }

import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import type { BooleanString, NumberFilter, NumberFilterEmptyString } from '@/api/openapi';
import FilterDetailPopover from '@/components/organisms/user/leadList/filter/FilterDetailPopover.vue';
import { getInitialValue } from '@/mixins/filter_service/constants';
import { getIncExc } from '@/utils/object';

type TNumberValue = string | NumberFilterEmptyString;
export type TValue = [TNumberValue, TNumberValue, BooleanString];
export type TFilterParam = { inc: NumberFilter } | { exc: NumberFilter };
export type TPropsCommon = {
  title?: string;
  value?: TValue;
  keyName?: string;
  isDisplayOnly?: boolean;
};

type TProps = TPropsCommon & {
  target?: TFilterParam;
  withIncExc?: boolean;
  withNoInput?: boolean;
  min?: number;
};
type TEmit = {
  hide: [TFilterParam];
};


export default /*@__PURE__*/_defineComponent({
  __name: 'NumberFilterCommon',
  props: {
    title: { default: '' },
    value: { default: () => getInitialValue('typeNumber') as TValue },
    keyName: { default: '' },
    isDisplayOnly: { type: Boolean, default: false },
    target: { default: () => ({ inc: { or: getInitialValue('typeNumber') as TValue } }) },
    withIncExc: { type: Boolean, default: true },
    withNoInput: { type: Boolean, default: true },
    min: { default: 0 }
  },
  emits: ["hide"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const i18n = useI18n();
const blankItem = { label: i18n.t('general.blankInput'), value: true };

const inputMinimum = ref<string | number | null>(null);
const inputMaximum = ref<string | number | null>(null);
const checkNoInput = ref(false);
const isInclude = ref(false);
const isInputMinimumValid = ref(true);
const isInputMaximumValid = ref(true);

const incExc = computed(() => getIncExc(props.target));
const targetDetail = computed(() => {
  return props.target?.[incExc.value]?.or || getInitialValue('typeNumber') as TValue;
});
const grouping = computed(() => isInclude.value ? 'inc' : 'exc' as const);
const isEmpty = computed(() => {
  return !Array.isArray(targetDetail.value)
    || targetDetail.value.length !== 3
    || isEmptyForNumberValue(targetDetail.value[0]) && isEmptyForNumberValue(targetDetail.value[1]) && !getBooleanEvenString(targetDetail.value[2]);
});
const groupingText = computed(() => {
  if (isEmpty.value) return '';
  return i18n.t(`general.filter.${incExc.value}`);
});
const displayValue = computed(() => {
  if (isEmpty.value) return '-';
  if (getBooleanEvenString(targetDetail.value[2])) return i18n.t('general.blankInput');
  const min = isEmptyForNumberValue(targetDetail.value[0]) ? props.min : targetDetail.value[0];
  const max = isEmptyForNumberValue(targetDetail.value[1]) ? i18n.t('general.max') : targetDetail.value[1];
  return i18n.t('general.range', { min, max });
});
const isValid = computed(() => {
  return isInputMinimumValid.value && isInputMaximumValid.value;
});

onMounted(() => {
  isInclude.value = getIncExc(props.target) !== 'exc';
});

const isEmptyForNumberValue = (num: string) => num === 'null';
const getBooleanEvenString = (val: string | boolean) => {
  if (val === 'true') {
    return true;
  } else if (val === 'false') {
    return false;
  } else if (typeof val === 'boolean') {
    return val;
  } else {
    return false;
  }
};
const popoverShow = () => {
  if (Array.isArray(targetDetail.value) && targetDetail.value.length === 3) {
    inputMinimum.value = isEmptyForNumberValue(targetDetail.value[0]) ? null : parseInt(targetDetail.value[0]);
    inputMaximum.value = isEmptyForNumberValue(targetDetail.value[1]) ? null : parseInt(targetDetail.value[1]);
    checkNoInput.value = getBooleanEvenString(targetDetail.value[2]);
  }
};
const hide = () => {
  emit('hide', { [grouping.value]: { or: makeQueryObject() } } as TFilterParam);
};
const makeQueryObject = () => {
  // フィルタの変更検知やクエリストリングのため、すべて文字列型として返す
  const min = isNaN(parseInt(inputMinimum.value?.toString())) ? 'null' : parseInt(inputMinimum.value?.toString()).toString();
  const max = isNaN(parseInt(inputMaximum.value?.toString())) ? 'null' : parseInt(inputMaximum.value?.toString()).toString();
  return [min, max, checkNoInput.value.toString()];
};
const changeCheckNoInput = (e: boolean) => {
  checkNoInput.value = e;
};

return (_ctx: any,_cache: any) => {
  const _component_BLayout = _resolveComponent("BLayout")!
  const _component_BListItem = _resolveComponent("BListItem")!
  const _component_BCheckbox = _resolveComponent("BCheckbox")!
  const _component_BInput = _resolveComponent("BInput")!

  return (_openBlock(), _createBlock(FilterDetailPopover, {
    ref: "popover",
    "is-include": isInclude.value,
    "onUpdate:isInclude": _cache[4] || (_cache[4] = ($event: any) => ((isInclude).value = $event)),
    title: _ctx.title,
    width: 260,
    "is-switch": _ctx.withIncExc,
    "is-display-only": _ctx.isDisplayOnly,
    "key-name": _ctx.keyName,
    valid: isValid.value,
    "is-custom": "",
    onHide: hide,
    onShow: popoverShow
  }, {
    reference: _withCtx(() => [
      _createVNode(_component_BLayout, { "align-center": "" }, {
        default: _withCtx(() => [
          _createVNode(_component_BListItem, null, {
            header: _withCtx(() => [
              _createVNode(_component_BLayout, { "justify-space-between": "" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.title), 1),
                  (_ctx.withIncExc)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: _normalizeClass(["grouping-text", { 'is-include': incExc.value === 'inc', 'inc-text': incExc.value === 'inc', 'exc-text': incExc.value === 'exc' }])
                      }, _toDisplayString(groupingText.value), 3))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            default: _withCtx(() => [
              _createElementVNode("div", null, _toDisplayString(displayValue.value), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      (_ctx.withNoInput)
        ? (_openBlock(), _createBlock(_component_BCheckbox, {
            key: 0,
            class: "my-100",
            "model-value": checkNoInput.value,
            label: blankItem.label,
            value: blankItem.value,
            onChange: changeCheckNoInput
          }, null, 8, ["model-value", "label", "value"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_BInput, {
          modelValue: inputMinimum.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((inputMinimum).value = $event)),
          valid: isInputMinimumValid.value,
          "onUpdate:valid": _cache[1] || (_cache[1] = ($event: any) => ((isInputMinimumValid).value = $event)),
          type: "number",
          placeholder: _ctx.$t('customField.number.minimum', { min: _ctx.min }),
          disabled: checkNoInput.value,
          min: _ctx.min,
          validation: ""
        }, null, 8, ["modelValue", "valid", "placeholder", "disabled", "min"]),
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('general.from')), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_BInput, {
          modelValue: inputMaximum.value,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((inputMaximum).value = $event)),
          valid: isInputMaximumValid.value,
          "onUpdate:valid": _cache[3] || (_cache[3] = ($event: any) => ((isInputMaximumValid).value = $event)),
          type: "number",
          placeholder: _ctx.$t('customField.number.maximum'),
          disabled: checkNoInput.value
        }, null, 8, ["modelValue", "valid", "placeholder", "disabled"]),
        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('general.to')), 1)
      ])
    ]),
    _: 1
  }, 8, ["is-include", "title", "is-switch", "is-display-only", "key-name", "valid"]))
}
}

})