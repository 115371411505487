import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed } from 'vue';
import type { TFilterParam, TPropsCommon } from './NumberFilterCommon.vue';
import NumberFilterCommon from './NumberFilterCommon.vue';

type TProps = TPropsCommon & {
  leadListFilterParams?: { [key: string]: TFilterParam };
};

export default /*@__PURE__*/_defineComponent({
  __name: 'NumberFilter',
  props: {
    title: {},
    value: {},
    keyName: {},
    isDisplayOnly: { type: Boolean },
    leadListFilterParams: {}
  },
  setup(__props: any) {

const props = __props;

const target = computed(() => props.leadListFilterParams[props.keyName]);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(NumberFilterCommon, {
    title: _ctx.title,
    value: _ctx.value,
    target: target.value,
    "key-name": _ctx.keyName,
    "is-display-only": _ctx.isDisplayOnly,
    "with-inc-exc": false,
    "with-no-input": false,
    min: 1
  }, null, 8, ["title", "value", "target", "key-name", "is-display-only"]))
}
}

})