import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import type { TFilterParam, TPropsCommon } from '../NumberFilterCommon.vue';
import NumberFilterCommon from '../NumberFilterCommon.vue';

type TProps = TPropsCommon & {
  customFieldItem?: TFilterParam;
};

export default /*@__PURE__*/_defineComponent({
  __name: 'NumberCustomFieldItem',
  props: {
    title: {},
    value: {},
    keyName: {},
    isDisplayOnly: { type: Boolean },
    customFieldItem: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(NumberFilterCommon, {
    title: _ctx.title,
    value: _ctx.value,
    target: _ctx.customFieldItem,
    "key-name": _ctx.keyName,
    "is-display-only": _ctx.isDisplayOnly
  }, null, 8, ["title", "value", "target", "key-name", "is-display-only"]))
}
}

})