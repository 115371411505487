<template>
  <div class="panel-body">
    <div
      v-if="isExistFilterCategory(systemItemComponentNames)"
      class="sub-title"
    >
      {{ $t('filterSelect.systemInfo.title') }}
    </div>
    <Component
      :is="key"
      v-for="(value, key) in filteredSystemItemComponentNames"
      :key="key"
      :title="$t(`filterSelect.systemInfo.item.${value}`)"
      :lead-list-filter-params="leadListFilterParams"
      :key-name="value"
      :is-display-only="isDisplayOnly"
      @hide="hide(value, $event)"
    />
    <div
      v-if="isExistFilterCategory(companyItemComponentNames)"
      class="sub-title"
    >
      {{ $t('filterSelect.companyInfo.title') }}
    </div>
    <Component
      :is="key"
      v-for="(value, key) in filteredCompanyItemComponentNames"
      :key="key"
      :title="$t(`filterSelect.companyInfo.item.${value}`)"
      :lead-list-filter-params="leadListFilterParams"
      :key-name="value"
      :is-display-only="isDisplayOnly"
      @hide="hide(value, $event)"
    />
    <div
      v-if="isExistFilterCategory(targetItemComponentNames)"
      class="sub-title"
    >
      {{ $t('filterSelect.callTargetInfo.title') }}
    </div>
    <Component
      :is="key"
      v-for="(value, key) in filteredTargetItemComponentNames"
      :key="key"
      :title="$t(`filterSelect.callTargetInfo.item.${value}`)"
      :lead-list-filter-params="leadListFilterParams"
      :key-name="value"
      :is-display-only="isDisplayOnly"
      @hide="hide(value, $event)"
    />
    <div
      v-if="isExistFilterCategory({...historyItemComponentNames, ...bulkMailItemComponentNames})"
      class="sub-title"
    >
      {{ $t('filterSelect.historyInfo.title') }}
    </div>
    <Component
      :is="key"
      v-for="(value, key) in filteredHistoryItemComponentNames"
      :key="key"
      :title="$t(`filterSelect.historyInfo.item.${value}`)"
      :lead-list-filter-params="leadListFilterParams"
      :key-name="value"
      :is-display-only="isDisplayOnly"
      @hide="hide(value, $event)"
    />
    <div
      v-if="isExistFilterCategory(bulkMailItemComponentNames)"
      class="bulk-mail-container"
      @mouseover="isShowBulkMailRemoveButton = true"
      @mouseleave="isShowBulkMailRemoveButton = false"
    >
      <BBtn
        v-if="isShowBulkMailRemoveButton"
        class="remove-button"
        size="mini"
        fab
        outline
        @click.stop="removeMultipleItems('bulkMails')"
      >
        <BIcon size="mini">
          close
        </BIcon>
      </BBtn>
      <span class="container-title">{{ $t('filterSelect.historyInfo.bulkMailItem.subTitle') }}</span>
      <Component
        :is="key"
        v-for="(value, key) in filteredBulkMailItemComponentNames"
        :key="key"
        :lead-list-filter-params="leadListFilterParams"
        :key-name="value"
        :is-display-only="isDisplayOnly"
        @hide="hide(value, $event)"
      />
    </div>
    <div
      v-if="isExistFilterCategory({...leadAdditionalItemComponentNames, ...leadSourceItemComponentNames})"
      class="sub-title"
    >
      {{ $t('filterSelect.leadAdditionalInfo.title') }}
    </div>
    <Component
      :is="key"
      v-for="(value, key) in filteredLeadAdditionalItemComponentNames"
      :key="key"
      :lead-list-filter-params="leadListFilterParams"
      :key-name="value"
      :is-display-only="isDisplayOnly"
      @hide="hide(value, $event)"
    />
    <div
      v-if="isExistFilterCategory(leadSourceItemComponentNames)"
      class="lead-source-container"
      @mouseover="isShowLeadSourceRemoveButton = true"
      @mouseleave="isShowLeadSourceRemoveButton = false"
    >
      <BBtn
        v-if="isShowLeadSourceRemoveButton"
        class="remove-button"
        size="mini"
        fab
        outline
        @click.stop="removeMultipleItems('leadSources')"
      >
        <BIcon size="mini">
          close
        </BIcon>
      </BBtn>
      <span class="container-title">{{ $t('filterSelect.leadAdditionalInfo.leadSourceItem.subTitle') }}</span>
      <Component
        :is="key"
        v-for="(value, key) in filteredLeadSourceItemComponentNames"
        :key="key"
        :title="$t(`filterSelect.leadAdditionalInfo.item.${value}`)"
        :lead-list-filter-params="leadListFilterParams"
        :key-name="value"
        :is-display-only="isDisplayOnly"
        @hide="hide(value, $event)"
      />
    </div>
    <div
      v-if="leadListFilterParams?.customFieldItems"
      class="sub-title"
    >
      {{ $t('filterSelect.customField.title') }}
    </div>
    <template v-for="(item, index) in customFieldItemsForPanel">
      <TextCustomFieldItem
        v-if="item.dataType === 'text'"
        :key="`text-${index}`"
        :title="item.name"
        :value="item.value"
        :custom-field-item="
          leadListFilterParams.customFieldItems[item.fieldKey]
        "
        :field-key="item.fieldKey"
        :is-display-only="isDisplayOnly"
        @hide="hide(item.fieldKey, $event, true)"
      />
      <DateTimeFilterCommon
        v-if="item.dataType === 'datetime'"
        :key="`datetime-${index}`"
        :is-display-only="isDisplayOnly"
        :key-name="item.fieldKey"
        :title="item.name"
        :filter-param="leadListFilterParams.customFieldItems[item.fieldKey]"
        is-custom
        @hide="hide(item.fieldKey, $event, true)"
      />
      <OptionCustomFieldItem
        v-if="['option', 'multi_option'].includes(item.dataType)"
        :key="`option-${index}`"
        :title="item.name"
        :value="item.value"
        :custom-field-item="
          leadListFilterParams.customFieldItems[item.fieldKey]
        "
        :field-key="item.fieldKey"
        :is-display-only="isDisplayOnly"
        @hide="hide(item.fieldKey, $event, true)"
      />
      <NumberCustomFieldItem
        v-else-if="item.dataType === 'number'"
        :key="`text-${index}`"
        :title="item.name"
        :value="item.value"
        :custom-field-item="
          leadListFilterParams.customFieldItems[item.fieldKey]
        "
        :key-name="item.fieldKey"
        :is-display-only="isDisplayOnly"
        @hide="hide(item.fieldKey, $event, true)"
      />
    </template>
    <div
      v-if="isExistFilterCategory(nextActionItemComponentNames)"
      class="sub-title"
    >
      {{ $t('filterSelect.nextActions.title') }}
    </div>
    <Component
      :is="key"
      v-for="(value, key) in filteredNextActionItemComponentNames"
      :key="key"
      :title="$t(`filterSelect.nextActions.item.${value}`)"
      :lead-list-filter-params="leadListFilterParams"
      :key-name="value"
      :true-text="value === 'nextActionsCompletedPresent' ? $t('nextAction.status.enum.done') : undefined"
      :false-text="value === 'nextActionsCompletedPresent' ? $t('nextAction.status.enum.unfinished') : undefined"
      :is-display-only="isDisplayOnly"
      @hide="hide(value, $event)"
    />
    <div
      v-if="isExistFilterCategory(duplicateItemComponentNames)"
      class="sub-title"
    >
      {{ $t('filterSelect.duplicateInfo.title') }}
    </div>
    <Component
      :is="key"
      v-for="(value, key) in filteredDuplicateItemComponentNames"
      :key="key"
      :title="$t(`filterSelect.duplicateInfo.item.${value}`)"
      :lead-list-filter-params="leadListFilterParams"
      :key-name="value"
      :is-display-only="isDisplayOnly"
      @hide="hide(value, $event)"
    />
  </div>
</template>

<script lang="ts">
import lodash from 'lodash'
import { defineComponent } from 'vue'
import { mapWaitingActions } from 'vue-wait'
import { mapGetters } from 'vuex'
import AssignedOperator from '@/components/organisms/user/leadList/filter/detail/AssignedOperator.vue'
import BulkMailEvents from '@/components/organisms/user/leadList/filter/detail/BulkMailEvents.vue'
import BulkMails from '@/components/organisms/user/leadList/filter/detail/BulkMails.vue'
import CallResults from '@/components/organisms/user/leadList/filter/detail/CallResults.vue'
import CompanyItemsBusinessCategory from '@/components/organisms/user/leadList/filter/detail/CompanyItemsBusinessCategory.vue'
import CompanyItemsNumberOfEmployees from '@/components/organisms/user/leadList/filter/detail/CompanyItemsNumberOfEmployees.vue'
import NumberCustomFieldItem from '@/components/organisms/user/leadList/filter/detail/customFieldItem/NumberCustomFieldItem.vue'
import OptionCustomFieldItem from '@/components/organisms/user/leadList/filter/detail/customFieldItem/OptionCustomFieldItem.vue'
import TextCustomFieldItem from '@/components/organisms/user/leadList/filter/detail/customFieldItem/TextCustomFieldItem.vue'
import CallTargetsCreatedAt from '@/components/organisms/user/leadList/filter/detail/DateTimeFilter.vue'
import NextActionsDoneAt from '@/components/organisms/user/leadList/filter/detail/DateTimeFilter.vue'
import NextActionsReservedAt from '@/components/organisms/user/leadList/filter/detail/DateTimeFilter.vue'
import OpportunitiesCreatedAt from '@/components/organisms/user/leadList/filter/detail/DateTimeFilter.vue'
import OpportunitiesStartedAt from '@/components/organisms/user/leadList/filter/detail/DateTimeFilter.vue'
import LastCallCalledAt from '@/components/organisms/user/leadList/filter/detail/DateTimeFilter.vue'
import LeadSourcesRegisteredAt from '@/components/organisms/user/leadList/filter/detail/DateTimeFilter.vue'
import DateTimeFilterCommon from '@/components/organisms/user/leadList/filter/detail/DateTimeFilterCommon.vue'
import DuplicateEmail from '@/components/organisms/user/leadList/filter/detail/DuplicateEmail.vue'
import FreeTags from '@/components/organisms/user/leadList/filter/detail/FreeTags.vue'
import LastLeadStage from '@/components/organisms/user/leadList/filter/detail/LastLeadStage.vue'
import LastLeadStageStayDays from '@/components/organisms/user/leadList/filter/detail/LastLeadStageStayDays.vue'
import LeadSources from '@/components/organisms/user/leadList/filter/detail/LeadSources.vue'
import NextActionsActionType from '@/components/organisms/user/leadList/filter/detail/NextActionsActionType.vue'
import NextActionsPriority from '@/components/organisms/user/leadList/filter/detail/NextActionsPriority.vue'
import NumberOfCall from '@/components/organisms/user/leadList/filter/detail/NumberFilter.vue'
import NextActionsCompletedPresent from '@/components/organisms/user/leadList/filter/detail/PresentFilter.vue'
import NextActionsPresent from '@/components/organisms/user/leadList/filter/detail/PresentFilter.vue'
import UnsubscriptionPresent from '@/components/organisms/user/leadList/filter/detail/PresentFilter.vue'
import OpportunitiesPresent from '@/components/organisms/user/leadList/filter/detail/PresentFilter.vue'
import NextActionsOwnerPresent from '@/components/organisms/user/leadList/filter/detail/PresentFilter.vue'
import SfCampaign from '@/components/organisms/user/leadList/filter/detail/SfCampaign.vue'
import SfRelatedLeadStatus from '@/components/organisms/user/leadList/filter/detail/SfRelatedLeadStatus.vue'
import TargetItemsDepartment from '@/components/organisms/user/leadList/filter/detail/TargetItemsDepartment.vue'
import TargetItemsEmail from '@/components/organisms/user/leadList/filter/detail/TargetItemsEmail.vue'
import TargetItemsPosition from '@/components/organisms/user/leadList/filter/detail/TargetItemsPosition.vue'
import CompanyItemsCompanyName from '@/components/organisms/user/leadList/filter/detail/TextFilter.vue'
import CompanyItemsCompanyAddress from '@/components/organisms/user/leadList/filter/detail/TextFilter.vue'
import TargetItemsTel from '@/components/organisms/user/leadList/filter/detail/TextFilter.vue'
import TargetItemsName from '@/components/organisms/user/leadList/filter/detail/TextFilter.vue'
import TargetItemsEmailText from '@/components/organisms/user/leadList/filter/detail/TextFilter.vue'
import CompanyItemsCompanyTel from '@/components/organisms/user/leadList/filter/detail/TextFilter.vue'
import NextActionsOwner from '@/components/organisms/user/leadList/filter/detail/UserSelectFilter.vue'
import NextActionsDoneBy from '@/components/organisms/user/leadList/filter/detail/UserSelectFilter.vue'
import filterService from '@/mixins/filter_service/index'
import { formatShorterDateTime, isDateValid } from '@/utils/date-time'
import { getIncExc } from '@/utils/object'

export default defineComponent({
  name: 'FilterControlPanelBody',
  components: {
    OptionCustomFieldItem,
    DateTimeFilterCommon,
    TextCustomFieldItem,
    NumberCustomFieldItem,
    OpportunitiesPresent,
    TargetItemsEmail,
    TargetItemsEmailText,
    OpportunitiesStartedAt,
    OpportunitiesCreatedAt,
    UnsubscriptionPresent,
    BulkMails,
    BulkMailEvents,
    CompanyItemsCompanyName,
    CompanyItemsCompanyTel,
    CompanyItemsCompanyAddress,
    CompanyItemsBusinessCategory,
    CompanyItemsNumberOfEmployees,
    TargetItemsDepartment,
    TargetItemsPosition,
    TargetItemsName,
    TargetItemsTel,
    CallResults,
    LastCallCalledAt,
    LastLeadStage,
    LastLeadStageStayDays,
    AssignedOperator,
    FreeTags,
    LeadSources,
    NextActionsActionType,
    NextActionsPriority,
    NextActionsReservedAt,
    NextActionsDoneAt,
    NextActionsPresent,
    NextActionsCompletedPresent,
    LeadSourcesRegisteredAt,
    CallTargetsCreatedAt,
    SfRelatedLeadStatus,
    SfCampaign,
    DuplicateEmail,
    NextActionsOwner,
    NextActionsOwnerPresent,
    NextActionsDoneBy,
    NumberOfCall,
  },
  mixins: [filterService],
  props: {
    leadListFilterParams: {
      type: Object,
    },
    isDisplayOnly: {
      type: Boolean,
      default: false,
    },
    filterCategoryVisible: {
      type: Boolean,
      default: true,
    },
    useStoreLeadList: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isShowBulkMailRemoveButton: false,
      isShowLeadSourceRemoveButton: false,
    }
  },
  computed: {
    ...mapGetters('user', {
      items: 'callTargets',
      customFields: 'customFields',
    }),
    ...mapGetters('userUi', [
      'leadListPageParams',
    ]),
    systemItemComponentNames() {
      return {
        CallTargetsCreatedAt: 'callTargetsCreatedAt',
        SfRelatedLeadStatus: 'sfRelatedLeadStatus',
        sfCampaign: 'sfCampaign',
      }
    },
    companyItemComponentNames() {
      return {
        CompanyItemsCompanyName: 'companyItemsCompanyName',
        CompanyItemsCompanyTel: 'companyItemsCompanyTel',
        CompanyItemsCompanyAddress: 'companyItemsCompanyAddress',
        CompanyItemsBusinessCategory: 'companyItemsBusinessCategory',
        CompanyItemsNumberOfEmployees: 'companyItemsNumberOfEmployees',
      }
    },
    targetItemComponentNames() {
      return {
        TargetItemsName: 'targetItemsName',
        TargetItemsTel: 'targetItemsTel',
        TargetItemsEmail: 'targetItemsEmail',
        TargetItemsEmailText: 'targetItemsEmailText',
        TargetItemsDepartment: 'targetItemsDepartment',
        TargetItemsPosition: 'targetItemsPosition',
      }
    },
    historyItemComponentNames() {
      return {
        CallResults: 'callResults',
        LastCallCalledAt: 'lastCallCalledAt',
        NumberOfCall: 'numberOfCall',
        OpportunitiesPresent: 'opportunitiesPresent',
        OpportunitiesCreatedAt: 'opportunitiesCreatedAt',
        OpportunitiesStartedAt: 'opportunitiesStartedAt',
        UnsubscriptionPresent: 'unsubscriptionPresent',
        LastLeadStageStayDays: 'lastLeadStageStayDays',
      }
    },
    bulkMailItemComponentNames() {
      return {
        BulkMails: 'bulkMails',
        BulkMailEvents: 'bulkMailEvents',
      }
    },
    leadAdditionalItemComponentNames() {
      return {
        LastLeadStage: 'lastLeadStage',
        AssignedOperator: 'assignedOperator',
        FreeTags: 'freeTags',
      }
    },
    leadSourceItemComponentNames() {
      return {
        LeadSources: 'leadSources',
        LeadSourcesRegisteredAt: 'leadSourcesRegisteredAt',
      }
    },
    nextActionItemComponentNames() {
      return {
        NextActionsActionType: 'nextActionsActionType',
        NextActionsPriority: 'nextActionsPriority',
        NextActionsReservedAt: 'nextActionsReservedAt',
        NextActionsDoneAt: 'nextActionsDoneAt',
        NextActionsPresent: 'nextActionsPresent',
        NextActionsCompletedPresent: 'nextActionsCompletedPresent',
        NextActionsOwner: 'nextActionsOwner',
        NextActionsOwnerPresent: 'nextActionsOwnerPresent',
        NextActionsDoneBy: 'nextActionsDoneBy',
      }
    },
    duplicateItemComponentNames() {
      return {
        DuplicateEmail: 'duplicateEmail',
      }
    },
    customFieldItemsForPanel() {
      if (
        this.leadListFilterParams.customFieldItems == null
          || Object.keys(this.leadListFilterParams.customFieldItems).length === 0
      )
        return {}

      const customFieldItems = []
      for (const fieldKey in this.leadListFilterParams.customFieldItems) {
        if (
          !this.leadListFilterParams.customFieldItems.hasOwnProperty(fieldKey)
        )
          continue

        const customField = this.customField(fieldKey)

        const incExc = getIncExc(
          this.leadListFilterParams.customFieldItems[fieldKey],
        )
        const params = this.leadListFilterParams.customFieldItems[fieldKey][
          incExc
        ].or

        const dataType = this.getDataType({ customField, params })

        const customFieldItem = {
          fieldKey,
          dataType,
          name:
              customField == null
                ? this.$t('customField.alreadyDeleted')
                : customField.name,
          value: '-',
        }

        if (
          customField
            && customField.dataType === 'text'
            && params
            && params.length > 0
        ) {
          customFieldItem.value = params
        } else if (
          customField
            && customField.dataType === 'datetime'
            && params
            && params.length === 2
        ) {
          const start = formatShorterDateTime(params[0])
          const end = formatShorterDateTime(params[1])
          customFieldItem.value = `${start} 〜 ${end}`
        } else if (
          customField
            && ['option', 'multi_option'].includes(customField.dataType)
            && params.length > 0
        ) {
          const customFieldOptionValues = customFieldItem.value = params.map(
            (v) => {
              if (parseInt(v) === -1) {
                return this.$t('general.blankInput')
              } else {
                const option = customField.options.find(
                  (o) => o.id === parseInt(v),
                )
                return option ? option.label : this.$t('general.delete.done')
              }
            },
          )
          customFieldItem.value = customFieldOptionValues.join(' / ')
        } else if (
          customField
            && customField.dataType === 'number'
            && params.length === 3
        ) {
          customFieldItem.value = params
        }

        customFieldItems.push(customFieldItem)
      }
      return customFieldItems
    },
    filteredSystemItemComponentNames() {
      const obj = {}
      for (const key in this.systemItemComponentNames) {
        const value = this.systemItemComponentNames[key]
        if (this.isExistFilterParam(value)) obj[key] = value
      }
      return obj
    },
    filteredCompanyItemComponentNames() {
      const obj = {}
      for (const key in this.companyItemComponentNames) {
        const value = this.companyItemComponentNames[key]
        if (this.isExistFilterParam(value)) obj[key] = value
      }
      return obj
    },
    filteredTargetItemComponentNames() {
      const obj = {}
      for (const key in this.targetItemComponentNames) {
        const value = this.targetItemComponentNames[key]
        if (this.isExistFilterParam(value)) obj[key] = value
      }
      return obj
    },
    filteredHistoryItemComponentNames() {
      const obj = {}
      for (const key in this.historyItemComponentNames) {
        const value = this.historyItemComponentNames[key]
        if (this.isExistFilterParam(value)) obj[key] = value
      }
      return obj
    },
    filteredBulkMailItemComponentNames() {
      const obj = {}
      for (const key in this.bulkMailItemComponentNames) {
        const value = this.bulkMailItemComponentNames[key]
        if (this.isExistFilterParam(value)) obj[key] = value
      }
      return obj
    },
    filteredLeadAdditionalItemComponentNames() {
      const obj = {}
      for (const key in this.leadAdditionalItemComponentNames) {
        const value = this.leadAdditionalItemComponentNames[key]
        if (this.isExistFilterParam(value)) obj[key] = value
      }
      return obj
    },
    filteredNextActionItemComponentNames() {
      const obj = {}
      for (const key in this.nextActionItemComponentNames) {
        const value = this.nextActionItemComponentNames[key]
        if (this.isExistFilterParam(value)) obj[key] = value
      }
      return obj
    },
    filteredLeadSourceItemComponentNames() {
      const obj = {}
      for (const key in this.leadSourceItemComponentNames) {
        const value = this.leadSourceItemComponentNames[key]
        if (this.isExistFilterParam(value)) obj[key] = value
      }
      return obj
    },
    filteredDuplicateItemComponentNames() {
      const obj = {}
      for (const key in this.duplicateItemComponentNames) {
        const value = this.duplicateItemComponentNames[key]
        if (this.isExistFilterParam(value)) obj[key] = value
      }
      return obj
    },
  },
  methods: {
    ...mapWaitingActions('user', {
      getCallTargetsAction: 'getCallTargetsWait',
      getCallTargetsMetaAction: 'getCallTargetsMetaWait',
    }),
    ...mapWaitingActions('userUi', {
      setLeadListFilterParamsAction: 'setLeadListFilterParamsWait',
      setLeadListPageParamsAction: 'setLeadListPageParamsWait',
    }),
    isExistFilterCategory(componentNames) {
      return this.filterCategoryVisible && Object.values(componentNames).some(this.isExistFilterParam)
    },
    isExistFilterParam(paramName) {
      return !(this.leadListFilterParams[paramName] == null)
    },
    isExistMultipleFilterParams(paramNames){
      let result = true
      paramNames.forEach((name) => {
        if (!this.isExistFilterParam(name)) result = false
      })
      return result
    },
    customField(fieldKey) {
      return this.customFields.find(
        (customField) => customField.fieldKey === fieldKey,
      )
    },
    hide(key, item, isCustomFieldItem) {
      const filterParams = lodash.cloneDeep(this.leadListFilterParams)
      if (isCustomFieldItem) {
        filterParams.customFieldItems[key] = lodash.cloneDeep(item)
      } else {
        filterParams[key] = lodash.cloneDeep(item)
      }
      this.setLeadListFilterParamsAction(filterParams)
      if (this.useStoreLeadList) {
        // 現在の表示数設定で1ページ目を表示する
        const pageParams = lodash.cloneDeep(this.leadListPageParams)
        this.setLeadListPageParamsAction(
          Object.assign(pageParams, { currentPage: 1 }),
        )
        this.getCallTargetsAction()
        this.getCallTargetsMetaAction()
      }
    },
    getDataType({ customField, params }) {
      let dataType = ''
      if (customField == null) {
        if (Array.isArray(params) && params.length === 3) {
          dataType = 'number'
        } else if (Array.isArray(params) && isDateValid(params[0])) {
          dataType = 'datetime'
        } else if (Array.isArray(params)) {
          dataType = 'option'
        } else if (typeof params === 'string') {
          dataType = 'text'
        }
      } else {
        dataType = customField.dataType
      }
      return dataType
    },
    removeMultipleItems(key) {
      this.removeMultipleFilterItem(this.getMultipleFilterKey(key))
    },
  },
});
</script>

<style lang="scss" scoped>
  .sub-title {
    font-size: 12px;
    color: $textcolor-light;
    padding-bottom: $basespace-50;
    border-bottom: 1px solid $bdcolor-base;
    margin-bottom: $basespace-100;
  }

  .panel-body {
    overflow-y: scroll;
    @include m-fixed-height(
      calc(100% - #{$filter-control-panel-header-height} - 50px)
    );
    padding: $basespace-200 $basespace-150;
    width: 100%;
  }

  .bulk-mail-container,
  .lead-source-container {
    @include m-fixed-width(190px);
    position: relative;
    padding: 4px;
    margin-top: 16px;
    margin-left: 8px;
    border-radius: 4px;
    background: $concrete-light;
    .remove-button {
      position: absolute;
      top: -13px;
      left: -13px;
      background-color: $bgcolor-white;
    }
    .container-title {
      display: block;
      margin-top: 10px;
      margin-left: 4px;
      font-size: $fontsize-100;
      color: $concrete-dark;
    }
    :deep(.filter-card) {
      width: 180px;
      margin-left: 0;
      background: $paper;
      .remove-button {
        display: none;
      }
    }
  }
</style>
