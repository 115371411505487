import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "panel-body" }
const _hoisted_2 = {
  key: 0,
  class: "sub-title"
}
const _hoisted_3 = {
  key: 1,
  class: "sub-title"
}
const _hoisted_4 = {
  key: 2,
  class: "sub-title"
}
const _hoisted_5 = {
  key: 3,
  class: "sub-title"
}
const _hoisted_6 = { class: "container-title" }
const _hoisted_7 = {
  key: 5,
  class: "sub-title"
}
const _hoisted_8 = { class: "container-title" }
const _hoisted_9 = {
  key: 7,
  class: "sub-title"
}
const _hoisted_10 = {
  key: 8,
  class: "sub-title"
}
const _hoisted_11 = {
  key: 9,
  class: "sub-title"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BIcon = _resolveComponent("BIcon")!
  const _component_BBtn = _resolveComponent("BBtn")!
  const _component_TextCustomFieldItem = _resolveComponent("TextCustomFieldItem")!
  const _component_DateTimeFilterCommon = _resolveComponent("DateTimeFilterCommon")!
  const _component_OptionCustomFieldItem = _resolveComponent("OptionCustomFieldItem")!
  const _component_NumberCustomFieldItem = _resolveComponent("NumberCustomFieldItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isExistFilterCategory(_ctx.systemItemComponentNames))
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.$t('filterSelect.systemInfo.title')), 1))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredSystemItemComponentNames, (value, key) => {
      return (_openBlock(), _createBlock(_resolveDynamicComponent(key), {
        key: key,
        title: _ctx.$t(`filterSelect.systemInfo.item.${value}`),
        "lead-list-filter-params": _ctx.leadListFilterParams,
        "key-name": value,
        "is-display-only": _ctx.isDisplayOnly,
        onHide: ($event: any) => (_ctx.hide(value, $event))
      }, null, 40, ["title", "lead-list-filter-params", "key-name", "is-display-only", "onHide"]))
    }), 128)),
    (_ctx.isExistFilterCategory(_ctx.companyItemComponentNames))
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$t('filterSelect.companyInfo.title')), 1))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredCompanyItemComponentNames, (value, key) => {
      return (_openBlock(), _createBlock(_resolveDynamicComponent(key), {
        key: key,
        title: _ctx.$t(`filterSelect.companyInfo.item.${value}`),
        "lead-list-filter-params": _ctx.leadListFilterParams,
        "key-name": value,
        "is-display-only": _ctx.isDisplayOnly,
        onHide: ($event: any) => (_ctx.hide(value, $event))
      }, null, 40, ["title", "lead-list-filter-params", "key-name", "is-display-only", "onHide"]))
    }), 128)),
    (_ctx.isExistFilterCategory(_ctx.targetItemComponentNames))
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.$t('filterSelect.callTargetInfo.title')), 1))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredTargetItemComponentNames, (value, key) => {
      return (_openBlock(), _createBlock(_resolveDynamicComponent(key), {
        key: key,
        title: _ctx.$t(`filterSelect.callTargetInfo.item.${value}`),
        "lead-list-filter-params": _ctx.leadListFilterParams,
        "key-name": value,
        "is-display-only": _ctx.isDisplayOnly,
        onHide: ($event: any) => (_ctx.hide(value, $event))
      }, null, 40, ["title", "lead-list-filter-params", "key-name", "is-display-only", "onHide"]))
    }), 128)),
    (_ctx.isExistFilterCategory({..._ctx.historyItemComponentNames, ..._ctx.bulkMailItemComponentNames}))
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.$t('filterSelect.historyInfo.title')), 1))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredHistoryItemComponentNames, (value, key) => {
      return (_openBlock(), _createBlock(_resolveDynamicComponent(key), {
        key: key,
        title: _ctx.$t(`filterSelect.historyInfo.item.${value}`),
        "lead-list-filter-params": _ctx.leadListFilterParams,
        "key-name": value,
        "is-display-only": _ctx.isDisplayOnly,
        onHide: ($event: any) => (_ctx.hide(value, $event))
      }, null, 40, ["title", "lead-list-filter-params", "key-name", "is-display-only", "onHide"]))
    }), 128)),
    (_ctx.isExistFilterCategory(_ctx.bulkMailItemComponentNames))
      ? (_openBlock(), _createElementBlock("div", {
          key: 4,
          class: "bulk-mail-container",
          onMouseover: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isShowBulkMailRemoveButton = true)),
          onMouseleave: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isShowBulkMailRemoveButton = false))
        }, [
          (_ctx.isShowBulkMailRemoveButton)
            ? (_openBlock(), _createBlock(_component_BBtn, {
                key: 0,
                class: "remove-button",
                size: "mini",
                fab: "",
                outline: "",
                onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.removeMultipleItems('bulkMails')), ["stop"]))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_BIcon, { size: "mini" }, {
                    default: _withCtx(() => _cache[6] || (_cache[6] = [
                      _createTextVNode(" close ")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('filterSelect.historyInfo.bulkMailItem.subTitle')), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredBulkMailItemComponentNames, (value, key) => {
            return (_openBlock(), _createBlock(_resolveDynamicComponent(key), {
              key: key,
              "lead-list-filter-params": _ctx.leadListFilterParams,
              "key-name": value,
              "is-display-only": _ctx.isDisplayOnly,
              onHide: ($event: any) => (_ctx.hide(value, $event))
            }, null, 40, ["lead-list-filter-params", "key-name", "is-display-only", "onHide"]))
          }), 128))
        ], 32))
      : _createCommentVNode("", true),
    (_ctx.isExistFilterCategory({..._ctx.leadAdditionalItemComponentNames, ..._ctx.leadSourceItemComponentNames}))
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.$t('filterSelect.leadAdditionalInfo.title')), 1))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredLeadAdditionalItemComponentNames, (value, key) => {
      return (_openBlock(), _createBlock(_resolveDynamicComponent(key), {
        key: key,
        "lead-list-filter-params": _ctx.leadListFilterParams,
        "key-name": value,
        "is-display-only": _ctx.isDisplayOnly,
        onHide: ($event: any) => (_ctx.hide(value, $event))
      }, null, 40, ["lead-list-filter-params", "key-name", "is-display-only", "onHide"]))
    }), 128)),
    (_ctx.isExistFilterCategory(_ctx.leadSourceItemComponentNames))
      ? (_openBlock(), _createElementBlock("div", {
          key: 6,
          class: "lead-source-container",
          onMouseover: _cache[4] || (_cache[4] = ($event: any) => (_ctx.isShowLeadSourceRemoveButton = true)),
          onMouseleave: _cache[5] || (_cache[5] = ($event: any) => (_ctx.isShowLeadSourceRemoveButton = false))
        }, [
          (_ctx.isShowLeadSourceRemoveButton)
            ? (_openBlock(), _createBlock(_component_BBtn, {
                key: 0,
                class: "remove-button",
                size: "mini",
                fab: "",
                outline: "",
                onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.removeMultipleItems('leadSources')), ["stop"]))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_BIcon, { size: "mini" }, {
                    default: _withCtx(() => _cache[7] || (_cache[7] = [
                      _createTextVNode(" close ")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('filterSelect.leadAdditionalInfo.leadSourceItem.subTitle')), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredLeadSourceItemComponentNames, (value, key) => {
            return (_openBlock(), _createBlock(_resolveDynamicComponent(key), {
              key: key,
              title: _ctx.$t(`filterSelect.leadAdditionalInfo.item.${value}`),
              "lead-list-filter-params": _ctx.leadListFilterParams,
              "key-name": value,
              "is-display-only": _ctx.isDisplayOnly,
              onHide: ($event: any) => (_ctx.hide(value, $event))
            }, null, 40, ["title", "lead-list-filter-params", "key-name", "is-display-only", "onHide"]))
          }), 128))
        ], 32))
      : _createCommentVNode("", true),
    (_ctx.leadListFilterParams?.customFieldItems)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.$t('filterSelect.customField.title')), 1))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customFieldItemsForPanel, (item, index) => {
      return (_openBlock(), _createElementBlock(_Fragment, null, [
        (item.dataType === 'text')
          ? (_openBlock(), _createBlock(_component_TextCustomFieldItem, {
              key: `text-${index}`,
              title: item.name,
              value: item.value,
              "custom-field-item": 
          _ctx.leadListFilterParams.customFieldItems[item.fieldKey]
        ,
              "field-key": item.fieldKey,
              "is-display-only": _ctx.isDisplayOnly,
              onHide: ($event: any) => (_ctx.hide(item.fieldKey, $event, true))
            }, null, 8, ["title", "value", "custom-field-item", "field-key", "is-display-only", "onHide"]))
          : _createCommentVNode("", true),
        (item.dataType === 'datetime')
          ? (_openBlock(), _createBlock(_component_DateTimeFilterCommon, {
              key: `datetime-${index}`,
              "is-display-only": _ctx.isDisplayOnly,
              "key-name": item.fieldKey,
              title: item.name,
              "filter-param": _ctx.leadListFilterParams.customFieldItems[item.fieldKey],
              "is-custom": "",
              onHide: ($event: any) => (_ctx.hide(item.fieldKey, $event, true))
            }, null, 8, ["is-display-only", "key-name", "title", "filter-param", "onHide"]))
          : _createCommentVNode("", true),
        (['option', 'multi_option'].includes(item.dataType))
          ? (_openBlock(), _createBlock(_component_OptionCustomFieldItem, {
              key: `option-${index}`,
              title: item.name,
              value: item.value,
              "custom-field-item": 
          _ctx.leadListFilterParams.customFieldItems[item.fieldKey]
        ,
              "field-key": item.fieldKey,
              "is-display-only": _ctx.isDisplayOnly,
              onHide: ($event: any) => (_ctx.hide(item.fieldKey, $event, true))
            }, null, 8, ["title", "value", "custom-field-item", "field-key", "is-display-only", "onHide"]))
          : (item.dataType === 'number')
            ? (_openBlock(), _createBlock(_component_NumberCustomFieldItem, {
                key: `text-${index}`,
                title: item.name,
                value: item.value,
                "custom-field-item": 
          _ctx.leadListFilterParams.customFieldItems[item.fieldKey]
        ,
                "key-name": item.fieldKey,
                "is-display-only": _ctx.isDisplayOnly,
                onHide: ($event: any) => (_ctx.hide(item.fieldKey, $event, true))
              }, null, 8, ["title", "value", "custom-field-item", "key-name", "is-display-only", "onHide"]))
            : _createCommentVNode("", true)
      ], 64))
    }), 256)),
    (_ctx.isExistFilterCategory(_ctx.nextActionItemComponentNames))
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.$t('filterSelect.nextActions.title')), 1))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredNextActionItemComponentNames, (value, key) => {
      return (_openBlock(), _createBlock(_resolveDynamicComponent(key), {
        key: key,
        title: _ctx.$t(`filterSelect.nextActions.item.${value}`),
        "lead-list-filter-params": _ctx.leadListFilterParams,
        "key-name": value,
        "true-text": value === 'nextActionsCompletedPresent' ? _ctx.$t('nextAction.status.enum.done') : undefined,
        "false-text": value === 'nextActionsCompletedPresent' ? _ctx.$t('nextAction.status.enum.unfinished') : undefined,
        "is-display-only": _ctx.isDisplayOnly,
        onHide: ($event: any) => (_ctx.hide(value, $event))
      }, null, 40, ["title", "lead-list-filter-params", "key-name", "true-text", "false-text", "is-display-only", "onHide"]))
    }), 128)),
    (_ctx.isExistFilterCategory(_ctx.duplicateItemComponentNames))
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_ctx.$t('filterSelect.duplicateInfo.title')), 1))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredDuplicateItemComponentNames, (value, key) => {
      return (_openBlock(), _createBlock(_resolveDynamicComponent(key), {
        key: key,
        title: _ctx.$t(`filterSelect.duplicateInfo.item.${value}`),
        "lead-list-filter-params": _ctx.leadListFilterParams,
        "key-name": value,
        "is-display-only": _ctx.isDisplayOnly,
        onHide: ($event: any) => (_ctx.hide(value, $event))
      }, null, 40, ["title", "lead-list-filter-params", "key-name", "is-display-only", "onHide"]))
    }), 128))
  ]))
}